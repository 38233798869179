np_jp = {

    /**
     * 画面ロックします。
     */
    lock: function () {
        $('#wrap').css('display', 'none');
        $('#loader-bg ,#loader').css('display', 'block');

        // 下記があることでフォームサブミットができないため削除
        // $('form').attr('onsubmit', 'return false;');
    },

    /**
     * 画面をアンロックします。
     */
    unlock: function () {
        $('#loader-bg').delay(500).fadeOut(300);
        $('#loader').delay(500).fadeOut(300);
        $('#wrap').css('display', 'block');

        // 下記があることでフォームサブミットができないため削除
        // $('form').removeAttr('onsubmit');
    },

    /**
     * メッセージ表示
     * @param icon
     * @param messages
     */
    showMessages: function (icon, messages) {

        // 画面上のエラーメッセージを削除
        $('div.append_error').remove();
        $('p.append_msg').remove();
        np_jp.removeHasError();

        if (!messages) {
            np_jp.showServerErrorMessages();
        }

        // console.log(messages);
        if (icon === 'error') {
            let information;
            // ダイアログ表示状態の場合はダイアログのinfo_messageのみ
            if ($('.dialog')[0].style['display'] === 'block') {
                information = $('.info.show_dialog');
            } else {
                information = $('.info');
            }

            let iconTag = $(information).find('.icon');
            let messageTag = $(information).find('.message');
            let messageTagSp = $(information).find('.info_message');
            $(iconTag).removeClass().addClass('icon');
            $(messageTag).empty().addClass('error_message');
            $(messageTagSp).empty().addClass('error_message');

            $(iconTag).addClass('icon_' + icon);
            let span = $('<span></span>').addClass(icon);
            if ($.isPlainObject(messages)) {
                $.each(messages, function (key, message) {
                    if (icon === 'warning' && message.length > 1) {
                        // 入力チェック：1つの項目に対して複数
                        $.each(message, function (index, term) {
                            setTag = $(span).clone(true).html(term);
                            $(messageTag).append(setTag);
                            $(messageTagSp).append(setTag);
                        });
                        if (isNaN(key)) {
                            np_jp.setHasError(key);
                        }
                    } else {
                        // 入力チェック：1つの項目に対して1つ
                        setTag = $(span).clone(true).html(message);
                        $(messageTag).append(setTag);
                        $(messageTagSp).append(setTag);
                        if (isNaN(key)) {
                            np_jp.setHasError(key);
                        }
                    }
                });

            } else {
                // 入力チェック以外の複数エラーは<br>タグで返ってくるため、line-heightで余白を指定
                $(span).html(messages).css('line-height', '1.9');
                $(messageTag).append(span);
                $(messageTagSp).append(span);
            }
            $(information).show();

        } else if (icon === 'warning') {
            let warning;
            // ダイアログ表示状態の場合はダイアログのinfo_messageのみ
            if ($('.dialog')[0].style['display'] === 'block') {
                warning = $('.warning.show_dialog');
            } else {
                warning = $('.warning');
            }
            let iconTag = $(warning).find('.icon');
            let messageTag = $(warning).find('.message');
            let messageTagSp = $(warning).find('.info_message');
            $(iconTag).removeClass().addClass('icon');
            $(messageTag).empty().addClass('error_message');
            $(messageTagSp).empty().addClass('error_message');

            $(iconTag).addClass('icon_' + icon);
            let span = $('<span></span>').addClass(icon);
            if ($.isPlainObject(messages)) {

                $.each(messages, function (key, message) {
                    console.log(key)
                    // エラーが発生した入力項目を取得
                    let $target = $('[name="' + key + '"]');
                    let target_type = $target.attr('type');
                    let target_msg_position = $target.data('msg_position');
                    let target_msg_position_selector = $target.data('msg_position_selector');

                    let append_tag = '<div class="error append_error"><div class="error_icon"></div><p class="append_msg error_message red">' + message + '</p></div>';

                    if (target_msg_position) {
                        $target[target_msg_position](target_msg_position_selector).append(append_tag);
                    } else if (key === 'zip_code_1' || key === 'zip_code_2') {
                        let zip_code_error = $('#zip-code-error');

                        if (message[0] === '郵便番号を入力してください。') {
                            let next_elem = zip_code_error.children();
                            if (next_elem.text() !== message[0]) {
                                zip_code_error.append(append_tag);
                            }
                        } else {
                            zip_code_error.append(append_tag);
                        }

                    } else if (key === 'birthday_year' || key === 'birthday_month' || key === 'birthday_day') {
                        let birthday_error = document.getElementById("birthday_error");
                        if (message[0] == '生年月日を入力してください。' || message[0] == '有効な日付を入力して下さい。' || message[0] == '本サイトは未成年者には販売しておりません。') {
                            birthday_error.innerHTML = append_tag;
                        }
                    } else if (key === 'gender_class') {
                        let gender_error = document.getElementById('gender-error');
                        if (message[0] == '性別を入力してください。') {
                            gender_error.innerHTML = append_tag;
                        }
                    } else if (target_type === 'text' || target_type === 'number' || target_type === 'email' || target_type === 'tel' || target_type === 'password') {
                        $target.after(append_tag);
                    } else if (target_type === 'checkbox' || target_type === 'radio') {
                        $target.parent().append(append_tag);
                    } else if (target_type === 'file') {
                        $target.parent().parent().parent().after(append_tag);
                    } else {
                        $target.after(append_tag);
                    }

                    if (isNaN(key)) {
                        np_jp.setHasError(key);
                    }
                });

            } else {
                // 入力チェック以外の複数エラーは<br>タグで返ってくるため、line-heightで余白を指定
                $(span).html(messages).css('line-height', '1.9');
                $(messageTag).append(span);
                $(messageTagSp).append(span);
            }
        }
    },

    /**
     * インフォメーションメッセージを表示します
     * @param message
     */
    showInformationMessages: function (message) {
        np_jp.showMessages('info', message);
    },

    /**
     * 成功メッセージを表示します
     * @param message
     */
    showSuccessMessages: function (message) {
        np_jp.showMessages('success', message);
    },


    /**
     * ajax通信失敗時のメッセージを表示します
     */
    showAjaxErrorMessages: function () {
        message = { "messages": "サーバーとの通信に失敗しました。" };
        np_jp.showMessages('error', message);
    },

    /**
     * サーバーエラー時のメッセージを表示します
     */
    showServerErrorMessages: function (message) {
        if (!message) {
            message = { "messages": "サーバーエラーが発生しました。" };
        }
        np_jp.showMessages('error', message);
    },

    /**
     * トークンエラー時のメッセージを表示します
     */
    showTokenErrorMessages: function () {
        message = { "messages": "画面情報が古くなっています。<a href=''>こちらをクリックして</a>画面をリロードしてください。" };
        np_jp.showMessages('error', message);
    },

    /**
     * 入力エラーの要素にhas_errorのクラスを付与します。
     * @param name
     */
    setHasError: function (name) {
        name = this.strReplaceDotToBracket(name);
        $elem = $('[name="' + name + '"]');
        if ($elem.length > 0) {
            $elem.addClass('has-error');
        }
    },

    /**
     *  has_errorのクラスを削除します。
     */
    removeHasError: function () {
        $('.has-error').removeClass('has-error');
        $('.error_message').removeClass('error_message');
    },

    /**
     * ドット記法の文字列をブラケット記法の文字列に変換します。
     * @param str
     * @returns string
     */
    strReplaceDotToBracket: function (str) {
        let arr = str.split('.');
        let arrLen = arr.length;
        if (arrLen === 1) {
            return str;
        }

        let ret = "";
        for (let i = 0; i < arrLen; i++) {
            if (i === 0) {
                ret += arr[i];
            } else {
                ret += '[' + arr[i] + ']';
            }
        }
        return ret;
    }
};

// 共通js
var pagetop_appear = false;

// ページ表示時にlockを解除する。(ヒストリーバック対策)
window.addEventListener('pageshow', function () {
    np_jp.unlock();
});

$(function () {
    $(window).on('load', function () {
        let line_banner_session = sessionStorage.getItem("line_banner");
        if (!line_banner_session) {
            $('.line_banner').removeClass("no-display-banner");
        }
    });

    $(window).scroll(function () {
        // トップへ戻るフェードイン
        var pagetop = $('#page-top');
        if ($(this).scrollTop() > 600) {
            if (pagetop_appear === false) {
                pagetop_appear = true;
                pagetop.fadeIn('slow');
            }
        } else {
            pagetop_appear = false;
            pagetop.fadeOut('out');
        }
    });

    // トップへ戻るボタンフェードアウト
    $('.page-top').click(function () {
        $('body, html').animate({ scrollTop: 0 }, 500); //0.5秒かけてトップへ戻る
        return false;
    });

    // アコーディオン
    $('.accordion').find('.accordion-title').click(function (e) {
        // メニュー表示/非表示
        $(this).parent('li').children('ul').slideToggle('fast');
        $(this).toggleClass("active");
        e.stopPropagation();
    });

    // LINEバナー閉じる
    $('.line_banner').find('.close_btn').click(function (e) {
        // セッションストレージに保存
        sessionStorage.setItem('line_banner', true);

        $('.line_banner').addClass('no-display-banner');
        return false;
    });
});

common = {
    // ダイアログを開く
    openDialog: function (target) {
        $('.' + target).fadeIn();
        return false;
    },
    // ダイアログを閉じる
    closeDialog: function () {
        $('.dialog').fadeOut();
        return false;
    }
};
